import React from 'react'
import Tooltip from '@material-ui/core/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faCheckSquare, faCoffee } from '@fortawesome/free-solid-svg-icons'
import './App.css'

library.add(fab, faCheckSquare, faCoffee)


function App() {
  return (
    <div className="App" >
      <style>{` 
      svg {padding: 20px; padding-left: 0} 
      a {text-decoration: none; color: white;} 
      path{fill:white}
      svg:hover path{fill:#61dafb}
      `}</style>
      <body className="App-body" >
        <container style={{ width: '80%', textAlign: 'left' }}>
          <div class="row">
            <img src='/andreaskater.jpg'
              style={{ maxHeight: 180, borderRadius: '100%', margin: 30, marginLeft: 0 }}
              alt="logo" />
          </div>
          <div class="row">
            <h2 style={{ marginBottom: 10, marginTop: 0, textAlign: 'left' }}>
              Andreas Kater
            </h2>
            <p style={{ marginTop: 10, textAlign: 'left' }}>
              <a href="mailto:andreas.kater@gmail.com"
                style={{ textDecoration: 'none', color: '#61dafb' }}>andreas.kater@gmail.com</a>
            </p>
          </div>
          <p style={{ fontSize: 20, textAlign: 'left' }}>
            I am a freelance full stack developer with a focus on Twilio and the Google Cloud Platform.
          <br />
            <br />
            Check out my profiles below and <a href="mailto:andreas.kater@gmail.com"
              style={{ textDecoration: 'none', color: '#61dafb' }}>get in touch</a>.
        </p >
          <hr style={{ color: 'white', width: '100%' }}></hr>
          <div class="row">
            <Tooltip title="Google Cloud Platform" placement="bottom" onClick={() => window.open("http://www.cloud.google.com", "_blank")}>
              <img src="/gcp.png" alt="" style={{ height: 36, width: 36, padding: 13, paddingLeft: 0, paddingRight: 20 }} />
            </Tooltip>
            <Tooltip title="Twilio" placement="bottom" onClick={() => window.open("http://www.twilio.com", "_blank")}>
              <img src="/twilio.png" alt="" style={{ height: 33, width: 33, padding: 17, paddingLeft: 0, paddingRight: 20 }} />
            </Tooltip>
            <Tooltip title="Github" placement="bottom">
              <a href="http://www.github.com/andreas-kater"
                target="_blank"
                rel="noopener noreferrer"
                ngbTooltip="See my github profile"
                placement="bottom">
                <FontAwesomeIcon icon={['fab', 'github']} style={{ height: 33, width: 33 }} />
              </a>
            </Tooltip>
            <Tooltip title="React" placement="bottom">
              <a href="http://www.reactjs.org"
                rel="noopener noreferrer"
                target="_blank"
              >
                <FontAwesomeIcon icon={['fab', 'react']} style={{ height: 33, width: 33 }} />
              </a>
            </Tooltip>
            <Tooltip title="Python" placement="bottom">
              <a href="http://www.python.org"
                rel="noopener noreferrer"
                target="_blank"
              >
                <FontAwesomeIcon icon={['fab', 'python']} style={{ height: 33, width: 33 }} />
              </a>
            </Tooltip>
            <Tooltip title="Twilio" placement="bottom">
              <a href="http://www.twitter.com/amkater"
                target="_blank"
                rel="noopener noreferrer"
                ngbTooltip="See my twitter page"
                placement="bottom">
                <FontAwesomeIcon icon={['fab', 'twitter']} style={{ height: 33, width: 33 }} />
              </a>
            </Tooltip>
            <Tooltip title="LinkedIn" placement="bottom">
              <a href="http://www.linkedin.com/in/andreas-kater-39562923"
                target="_blank"
                rel="noopener noreferrer"
                ngbTooltip="See my linkedin profile"
                placement="bottom">
                <FontAwesomeIcon icon={['fab', 'linkedin']} style={{ height: 33, width: 33 }} />
              </a>
            </Tooltip>
          </div>
        </container>
      </body>
    </div >
  )
}

export default App
